import Component1 from "./Component1";
import PropTypes from "prop-types";
import "./FeaturesDescription.css";

const FeaturesDescription = ({ className = "" }) => {
  return (
    <section className={`features-description ${className}`}>
      <div className="features-header-container">
        {/* <img
          className="features-header-container-child"
          loading="lazy"
          alt=""
          src="/group-31@2x.png"
        />
        <img
          className="features-header-container-item"
          loading="lazy"
          alt=""
          src="/ellipse-21.svg"
        />
             <img
          className="features-header-container-item-2"
          loading="lazy"
          alt=""
          src="/rectangle-18.svg"
        />
              <img
          className="features-header-container-item-3"
          loading="lazy"
          alt=""
          src="/groupp-5.svg"
        /> */}
        {/* <div className="features-title-dots" /> */}
        {/* <div className="features-title-dots1" /> */}
        {/* <div className="features-title-dots2" /> */}
        <div className="frame-parent6">
          <div className="frame-wrapper2">
            <div className="frame-parent7">
              <div className="our-features-wrapper">
                <h1 className="our-features">Our Features</h1>
              </div>
              <h3 className="effortless-facebook-video">
                Effortless Facebook Video Downloads at Your Fingertips
              </h3>
            </div>
          </div>
          <div className="feature-highlights-container">
            {/* <div className="feature-highlights-container-child" /> */}
            <div className="feature-highlights-container-inner">
              <img
                className="frame-child12"
                loading="lazy"
                alt=""
                src="/group-30.svg"
              />
            </div>
            <div className="highlights-row">
              <div className="highlights-column">
                {/* <div> */}
                <img
                  className="group-icon2"
                  loading="lazy"
                  alt=""
                  src="/group.svg"
                />
                {/* <div className="features-title-dots1" /> */}
                {/* </div> */}
              </div>
              <div className="free-download-info">
                <div className="high-quality-wrapper">
                  <h2 className="high-quality">High Quality</h2>
                </div>
                <div className="our-facebook-downloader">
                  Our Facebook Downloader helps you to save video in Mp4, Full
                  HD and 2K videos with sound
                </div>
              </div>
            </div>
            <div className="highlights-row1">
              <div className="frame-parent9">
                <div className="group-container">
                  <div className="group">
                    <div className="group-group">
                      <img className="group-icon3" alt="" src="/group-1.svg" />
                      {/* <div className="group1">
                        <div className="group2">
                          <div>
                          <img
                            className="group-icon4"
                            alt=""
                            src="/group-2.svg"
                          />
                              <div className="features-title-dots" />
                          </div>
                          <img
                            className="group-icon5"
                            alt=""
                            src="/group-3.svg"
                          />
                          <img
                            className="group-icon6"
                            alt=""
                            src="/group-4.svg"
                          />
                          <img
                            className="group-icon7"
                            loading="lazy"
                            alt=""
                            src="/group-5.svg"
                          />
                          <img
                            className="group-icon8"
                            alt=""
                            src="/group-6.svg"
                          />
                        </div>
                        <img
                          className="group-icon9"
                          alt=""
                          src="/group-7.svg"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="frame-parent10">
                  <div className="simply-to-use-wrapper">
                    <h2 className="high-quality">Simply to Use</h2>
                  </div>
                  <div className="our-facebook-downloader">
                    Easily download Facebook videos on any device with a simple
                    and straightforward method.
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-highlights-container-inner1">
              <div className="objects-container-parent">
                <div className="objects-container">
                  <div className="free-title-container">
                    <div className="free-paragraph">
                      {/* <div> */}
                      <img
                        className="objects-icon"
                        loading="lazy"
                        alt=""
                        src="/objects.svg"
                      />
{/* <div className="features-title-dots2" /> */}
                      {/* </div> */}
                    </div>
                    <div className="free-download-info">
                      <div className="free-download-title">
                        <h2 className="high-quality">Absolutely Free</h2>
                      </div>
                      <div className="our-facebook-downloader">
                        You can save video from Facebook for free. We only post
                        a few ads to support our website.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="free-info-icon">
                  {/* <div className="info-icon-circle" /> */}
                  {/* <img
          className="features-header-container-item"
          loading="lazy"
          alt=""
          src="/ellipse-21.svg"
        /> */}
            {/* <img
          className="features-header-container-item-1"
          loading="lazy"
          alt=""
          src="/vector-4.svg"
        /> */}
     
                  <input className="info-icon-container" type="checkbox" />
                </div>
              </div>


            </div>
            <img
          className="features-right-child1"
          loading="lazy"
          alt=""
          src="/group-744.svg"
        />
          </div>
          {/* <div className="mobile-download-container-wrapper">
            <div className="mobile-download-container">
              <div className="mobile-content-container">
                <div className="mobile-info">
                  <div className="mobile-title-container">
                    <h1 className="how-to-use1">How To Use?</h1>
                    <div className="mobile-paragraph-container">
                      <h3 className="follow-these-easy">
                        Follow These Easy Steps to Download and Keep Facebook
                        Videos
                      </h3>
                    </div>
                  </div>
                  <div className="steps-container">
                    <div className="frame-parent11">
                      <div className="frame-parent12">
                        <img
                          className="frame-child13"
                          loading="lazy"
                          alt=""
                          src="/group-19.svg"
                        />
                        <div className="steps-content">
                          <div className="free-download-info">
                            <h3 className="copy-the-url">
                              Copy the URL of the video
                            </h3>
                            <div className="copy-the-video">
                              Copy the video URL from the browser address bar to
                              the clipboard, or right-click the video and select
                              it from the context menu.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-parent13">
                        <img
                          className="frame-child14"
                          loading="lazy"
                          alt=""
                          src="/group-18.svg"
                        />
                        <div className="frame-wrapper3">
                          <div className="paste-url-into-the-input-field-parent">
                            <h3 className="copy-the-url">
                              Paste URL into the input field
                            </h3>
                            <div className="go-back-to">
                              Go back to the browser tab with the Facebook video
                              converter window open and paste the link into the
                              input field.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-parent13">
                        <img
                          className="frame-child14"
                          loading="lazy"
                          alt=""
                          src="/group-17.svg"
                        />
                        <div className="frame-wrapper4">
                          <div className="paste-url-into-the-input-field-parent">
                            <h3 className="click-the-download">
                              Click the "Download" button
                            </h3>
                            <div className="copy-the-video">
                              Just click the dropdown list before the "Download"
                              button in our online Facebook video converter and
                              select the preferred format option.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Component1 property1="Frame 43" />
              </div>
              <div className="mobile-circle-container">
                <div className="info-icon-circle" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

FeaturesDescription.propTypes = {
  className: PropTypes.string,
};

export default FeaturesDescription;
