import React, { useRef } from "react";
import CarouselContainer from "../components/CarouselContainer";
import FeaturesDescription from "../components/FeaturesDescription";
import PhoneImageContainer from "../components/PhoneImageContainer";
import FrameComponent from "../components/FrameComponent";
import "./UIDesign.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HowToUse from "../components/HowToUse";

const UIDesign = () => {
  // Create refs
  const featuresRef = useRef(null);
  const frameRef = useRef(null);

  return (
    <>
    <Header featuresRef={featuresRef} frameRef={frameRef} />
    {/* <div style={{ paddingTop: "100px" }}>  */}
        <CarouselContainer />
        <FeaturesDescription />
        <div ref={featuresRef}>
            <HowToUse />
        </div>
        <PhoneImageContainer />
        <div ref={frameRef}>
            <FrameComponent />
        </div>
        <Footer />
    {/* </div> */}
</>
  );
};

export default UIDesign;