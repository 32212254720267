import { useCallback } from "react";
import GroupComponent1 from "./GroupComponent1";
import PropTypes from "prop-types";
import "./FrameComponent.css";

const FrameComponent = ({ className = "" }) => {
  const onAccordionHeaderClick = useCallback((event) => {
    const element = event.target;
    const accItem = element.closest("[data-acc-item]") || element;
    const accContent = accItem.querySelector("[data-acc-content]");
    const imgElement = accItem.querySelector(".group-35-open-item");
    const isOpen = accItem.hasAttribute("data-acc-open");

    const nextOuterSibling =
      accItem?.nextElementSibling || accItem?.parentElement?.nextElementSibling;
    const prevOuterSibling =
      accItem?.previousElementSibling ||
      accItem?.parentElement?.previousElementSibling;
    const siblingContainerAccItem = accItem?.hasAttribute("data-acc-original")
      ? accItem?.nextElementSibling ||
        nextOuterSibling?.querySelector("[data-acc-item]") ||
        nextOuterSibling
      : accItem?.previousElementSibling ||
        prevOuterSibling?.querySelector("[data-acc-item]") ||
        prevOuterSibling;
    const siblingAccItem =
      siblingContainerAccItem?.querySelector("[data-acc-item]") ||
      siblingContainerAccItem;

    if (!siblingAccItem) return;
    const originalDisplay = "flex";
    const siblingDisplay = "flex";

    const accordion__open = "accordion__open";
    const accordion__close = "accordion__close";

    if (isOpen) {
      accContent?.classList.remove(accordion__open);
      accContent?.classList.add(accordion__close);

      // Change image to "Group 34" when closed
      if (imgElement) imgElement.src = "/group-44.png";

      setTimeout(() => {
        if (accItem) {
          accItem.style.display = "none";
          siblingAccItem.style.display = siblingDisplay;
        }
      }, 100);
    } else {
      if (accItem) {
        accItem.style.display = "none";
        siblingAccItem.style.display = originalDisplay;
      }
      const siblingAccContent =
        siblingAccItem?.querySelector("[data-acc-content]");
      setTimeout(() => {
        siblingAccContent?.classList.remove(accordion__close);
        siblingAccContent?.classList.add(accordion__open);
      }, 1);

      // Change image to "Group 5" when opened
      if (imgElement) imgElement.src = "/group-34@2x.png";
    }
  }, []);

  return (
    <section className={`faq-info-container-wrapper ${className}`}>
      <div className="faq-info-container">
        <div className="faq-title">
          <div className="faq-title-content">
            <div className="faqs-wrapper">
              <h1 className="faqs1">FAQs</h1>
            </div>
            <h3 className="got-questions-weve">
              Got Questions? We’ve Got Answers—Explore Our FAQs
            </h3>
          </div>
        </div>
        <div className="faq-questions" data-acc-group>
          <GroupComponent1
            clickTheDownloadButton={`Click the "Download" button`}
          />
          <div className="accordion-item-mock" data-acc-item data-acc-open>
            <div
              className="group-35-open"
              data-acc-header
              onClick={onAccordionHeaderClick}
            >
              <div className="group-35-open-child" />
              <img
                className="group-35-open-item"
                alt=""
                src="/group-44.png"
              />
              <div className="click-the-download2">
                Click the "Download" button
              </div>
              <div className="accordion-content" />
            </div>
            <div className="accordion-content1" data-acc-content>
              <div className="accordion-content-wrap">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </div>
            </div>
          </div>
          <GroupComponent1
            clickTheDownloadButton="How to download a video from Facebook on mobile phone?"
           
          />
          <div className="accordion-item-mock" data-acc-item data-acc-open>
            <div
              className="group-35-open"
              data-acc-header
              onClick={onAccordionHeaderClick}
            >
              <div className="group-35-open-child" />
              <img
                className="group-35-open-item"
                alt=""
                src="/group-44.png"
              />
              <div className="click-the-download3">
                How to download a video from Facebook on mobile phone?
              </div>
              <div className="accordion-content" />
            </div>
            <div className="accordion-content1" data-acc-content>
              <div className="accordion-content-wrap">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </div>
            </div>
          </div>
          <GroupComponent1
            clickTheDownloadButton="Is the Facebook video downloader app free?"
        
          />
          <div className="accordion-item-mock" data-acc-item data-acc-open>
            <div
              className="group-35-open"
              data-acc-header
              onClick={onAccordionHeaderClick}
            >
              <div className="group-35-open-child" />
              <img
                className="group-35-open-item"
                alt=""
                src="/group-44.png"
              />
              <div className="click-the-download4">
                Is the Facebook video downloader app free?
              </div>
              <div className="accordion-content" />
            </div>
            <div className="accordion-content1" data-acc-content>
              <div className="accordion-content-wrap">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </div>
            </div>
          </div>
          <GroupComponent1
            clickTheDownloadButton="What browsers does Facebook downloader support?"
         
          />
          <div className="accordion-item-mock" data-acc-item data-acc-open>
            <div
              className="group-35-open"
              data-acc-header
              onClick={onAccordionHeaderClick}
            >
              <div className="group-35-open-child" />
              <img
                className="group-35-open-item"
                alt=""
                src="/group-44.png"
              />
              <div className="click-the-download5">
                What browsers does Facebook downloader support?
              </div>
              <div className="accordion-content" />
            </div>
            <div className="accordion-content1" data-acc-content>
              <div className="accordion-content-wrap">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </div>
            </div>
          </div>
          <GroupComponent1
            clickTheDownloadButton="Why is the video playing and not downloading?"
          
          />
          <div className="accordion-item-mock" data-acc-item data-acc-open>
            <div
              className="group-35-open"
              data-acc-header
              onClick={onAccordionHeaderClick}
            >
              <div className="group-35-open-child" />
              <img
                className="group-35-open-item"
                alt=""
                src="/group-44.png"
              />
              <div className="click-the-download6">
                Why is the video playing and not downloading?
              </div>
              <div className="accordion-content" />
            </div>
            <div className="accordion-content1" data-acc-content>
              <div className="accordion-content-wrap">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </div>
            </div>
          </div>
          <GroupComponent1
            clickTheDownloadButton="What video quality does Facebook video downloader support?"
          
          />
          <div className="accordion-item-mock" data-acc-item data-acc-open>
            <div
              className="group-35-open"
              data-acc-header
              onClick={onAccordionHeaderClick}
            >
              <div className="group-35-open-child" />
              <img
                className="group-35-open-item"
                alt=""
                src="/group-44.png"
              />
              <div className="click-the-download7">
                What video quality does Facebook video downloader support?
              </div>
              <div className="accordion-content" />
            </div>
            <div className="accordion-content1" data-acc-content>
              <div className="accordion-content-wrap">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </div>
            </div>
          </div>
          <GroupComponent1
            clickTheDownloadButton="Do you track the inserted links?"
          
          />
          <div className="accordion-item-mock" data-acc-item data-acc-open>
            <div
              className="group-35-open"
              data-acc-header
              onClick={onAccordionHeaderClick}
            >
              <div className="group-35-open-child" />
              <img
                className="group-35-open-item"
                alt=""
                src="/group-44.png"
              />
              <div className="click-the-download8">
                Do you track the inserted links?
              </div>
              <div className="accordion-content" />
            </div>
            <div className="accordion-content1" data-acc-content>
              <div className="accordion-content-wrap">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </div>
            </div>
          </div>
          {/* Add the remaining GroupComponent1 and accordion-item-mock elements here */}
        </div>
      </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
