import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Component1.css";

const Component1 = ({ className = "", property1 = "Frame 43" }) => {
  const [selectedOption, setSelectedOption] = useState("Web Browser");

  const images = {
    "Web Browser": "/assets/webimage.png",
    "Mobile Browser": "/assets/mobileimage.png",
    "App": "assets/appimage.png",
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className={`component-1 ${className}`} data-property1={property1}>
      <div className="frame-parent5">
        <div className="browser-top-bar-parent">
          <div className="browser-top-bar" />
          <div className="browser-address-bar-parent">
            <div className="browser-address-bar" />

            {/* Web Browser Button */}
            <button
              className={`browser-content-parent ${selectedOption === "Web Browser" ? "active" : ""}`}
              onClick={() => handleOptionClick("Web Browser")}
            >
              <div className="browser-content" />
              <div className="browser-title-icon-container-parent">
                <div className="browser-title-icon-container">
                  <img
                    className="browser-title-icon"
                    alt=""
                    src="/browser-title-icon.svg"
                  />
                </div>
                <div className={`web-browser ${selectedOption === "Web Browser" ? "active-text" : ""}`}>Web Browser</div>
              </div>
            </button>

            {/* Mobile Browser Button */}
            <button
              className={`browser-content-parent ${selectedOption === "Mobile Browser" ? "active" : ""}`}
              onClick={() => handleOptionClick("Mobile Browser")}
            >
              <div className="browser-content" />
              <div className="browser-title-icon-container-parent">
                <div className="browser-title-icon-container">
                  <img
                    className="browser-title-icon"
                    alt=""
                    loading="lazy"
                    src="/group-8.svg"
                  />
                </div>
                <div className={`web-browser ${selectedOption === "Mobile Browser" ? "active-text" : ""}`}>Mobile Browser</div>
              </div>
            </button>

            {/* App Button */}
            <button
              className={`browser-content-parent ${selectedOption === "App" ? "active" : ""}`}
              onClick={() => handleOptionClick("App")}
            >
              <div className="browser-content" />
              <div className="browser-title-icon-container-parent">
                <div className="browser-title-icon-container">
                  <img
                    className="browser-title-icon"
                    alt=""
                    loading="lazy"
                    src="/group-9.svg"
                  />
                </div>
                <div className={`web-browser ${selectedOption === "App" ? "active-text" : ""}`}>App</div>
              </div>
            </button>
          </div>
          <div className="browser-bottom-bar" />
        </div>

        {/* Dynamic Image */}
        <img
          className="mask-group-icon"
          alt=""
          src={images[selectedOption]}
        />
      </div>
    </div>
  );
};

Component1.propTypes = {
  className: PropTypes.string,
  property1: PropTypes.string,
};

export default Component1;