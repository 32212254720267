import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
<>
<footer className="footer">
        <div className="footer-child" />
        <div className="copyright-2024">
          Copyright © 2024 ACE THINKER. All Rights Reserved.
        </div>
      </footer>
</>
  )
}

export default Footer