import PropTypes from "prop-types";
import "./PhoneImageContainer.css";

const PhoneImageContainer = ({ className = "" }) => {
  return (
    <section className={`phone-image-container ${className}`}>
     
      <div className="phone-image">
        <div className="phone-top-circle" />
        <div className="phone-right-circles">
          <img className="phone-icon" alt="" src="/phone-icon.svg" />
          <div className="phone-bottom-circle" />
          <div className="phone-circles" />
          <div className="phone-bottom-right-circle" />
          <div className="phone-circles1" />
        </div>
        <div className="phone-image">
        <img className="phone-icon1" alt="" src="/group-95.svg" />

        </div>
        <div className="phone-left-circles">
          <div className="phone-left-circle" />
          <img
            className="phone-left-circles-child"
            loading="lazy"
            alt=""
            src="/group-27@2x.png"
          />
        </div>
      </div>
      <div>
        
      </div>
      <div className="phone-description-container">
        <div className="phone-description">
          <div className="phone-description-content">
            <h1 className="download-facebook-videos">
              Download Facebook videos on mobile phone
            </h1>
            <div className="phone-description-paragraph">
              <h3 className="if-you-want-container">
                <p className="if-you-want">
                  If you want to start facebook video download in mp4 via link
                  on mobile phone (Android), you will need to launch the
                  Facebook Video downloader app and find the video you want to
                  save from Facebook.
                </p>
                <p className="if-you-want">&nbsp;</p>
                <p className="if-you-want">
                  Click on it and select "Copy Link". After that, open the fb
                  video downloader and paste the link into the text box at the
                  top of the page.
                </p>
              </h3>
            </div>
         
          <div className="phone-source-container">
          <div>
          <a href=" https://play.google.com/store/apps/details?id=com.fbvideodownloader.downloadfbvideos" target="_blank" rel="noopener noreferrer">
            <img
              className="pngwingcom-2-icon"
              loading="lazy"
              alt=""
              src="/pngwingcom-2@2x.png"
            />
            </a>
            
            </div>
            
            </div>
                    {/* <img
          className="features-header-container-item-4"
          loading="lazy"
          alt=""
          src="/ellipse-21.svg"
        />
             <img
          className="features-header-container-item-5"
          loading="lazy"
          alt=""
          src="/rectangle-18.svg"
        />  
              <img
          className="features-header-container-item-6"
          loading="lazy"
          alt=""
          src="/groupp-5.svg"
        /> */}
    
          </div>
        </div>
      </div>
      {/* <div className="faq-container"> */}
        {/* <div className="faq-content-container"> */}
          {/* <div className="faq-icon-circle"> */}
            {/* <div className="faq-circle" /> */}
          {/* </div> */}
          {/* <img
            className="faq-content-container-child"
            loading="lazy"
            alt=""
            src="/group-51.svg"
          /> */}
    
        {/* </div> */}
      {/* </div> */}
      <img
              className="phone-right-child"
              loading="lazy"
              alt=""
              src="/group-777.svg"
            />
    </section>
  );
};

PhoneImageContainer.propTypes = {
  className: PropTypes.string,
};

export default PhoneImageContainer;
