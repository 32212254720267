import React, { useState } from "react";
import { Link } from "react-router-dom"; 
import "./Header.css";

const Header = ({ featuresRef, frameRef }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/'); 

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetTop = ref.current.getBoundingClientRect().top + window.scrollY; // Get the current position of the ref
      const headerOffset = 100; // Increase this value based on your header height
      const targetPosition = offsetTop - headerOffset; // Adjust scroll position
      
      window.scrollTo({
        top: targetPosition,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  const handleHomeClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setActiveLink('/'); // Set active link to Home
  };

  const handleLinkClick = (link, ref) => {
    setActiveLink(link); 

    if (ref) {
      scrollToRef(ref);
    }
    
    toggleMenu();
  };

  return (
    <div className="header">
      <div className="content">
        <div className="video-downloader-container">
          <Link
            className="video-downloader"
            to="/" 
            onClick={() => window.location.reload()} // Refresh page on logo click
          >
            Video Downloader
          </Link>
          <div className="nav-container">
            <div className="home-parent">
              <div className="menu-icon-container" onClick={toggleMenu}>
                <img className="menu-icon" src="./assets/menu-icon.png" alt="Menu" />
              </div>
              <div className={`sidebar ${isMenuOpen ? "open" : ""}`}>
                <div className="open-nav-button">
                  <Link
                    className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
                    to="/"
                    onClick={handleHomeClick} // Call handleHomeClick for Home link
                  >
                    Home
                  </Link>
                  <Link
                    className={`nav-link ${activeLink === '#features' ? 'active' : ''}`}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick('#features', featuresRef);
                    }}
                  >
                    How to Use?
                  </Link>
                  <Link
                    className={`nav-link ${activeLink === '#faqs' ? 'active' : ''}`}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick('#faqs', frameRef);
                    }}
                  >
                    FAQs
                  </Link>
                </div>
              </div>
              <div className="desktop-nav">
                <Link
                  className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
                  to="/"
                  onClick={handleHomeClick} // Call handleHomeClick for Home link
                >
                  Home
                </Link>
                <Link
                  className={`nav-link ${activeLink === '#features' ? 'active' : ''}`}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('#features', featuresRef);
                  }}
                >
                  How to Use?
                </Link>
                <Link
                  className={`nav-link ${activeLink === '#faqs' ? 'active' : ''}`}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('#faqs', frameRef);
                  }}
                >
                  FAQs
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;