import { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import "./GroupComponent1.css";

const GroupComponent1 = ({
  className = "",
  clickTheDownloadButton,
  clickTheDownloadWidth,
}) => {
  const clickTheDownloadStyle = useMemo(() => {
    return {
      width: clickTheDownloadWidth,
    };
  }, [clickTheDownloadWidth]);

  const onAccordionHeaderClick = useCallback((event) => {
    const element = event.target;

    const accItem = element.closest("[data-acc-item]") || element;
    const accContent = accItem.querySelector("[data-acc-content]");
    const isOpen = accItem.hasAttribute("data-acc-open");
    const nextOuterSibling =
      accItem?.nextElementSibling || accItem?.parentElement?.nextElementSibling;
    const prevOuterSibling =
      accItem?.previousElementSibling ||
      accItem?.parentElement?.previousElementSibling;
    const siblingContainerAccItem = accItem?.hasAttribute("data-acc-original")
      ? accItem?.nextElementSibling ||
        nextOuterSibling?.querySelector("[data-acc-item]") ||
        nextOuterSibling
      : accItem?.previousElementSibling ||
        prevOuterSibling?.querySelector("[data-acc-item]") ||
        prevOuterSibling;
    const siblingAccItem =
      siblingContainerAccItem?.querySelector("[data-acc-item]") ||
      siblingContainerAccItem;

    if (!siblingAccItem) return;
    const originalDisplay = "flex";
    const siblingDisplay = "flex";

    const accordion__open = "accordion__open";
    const accordion__close = "accordion__close";

    if (isOpen) {
      accContent?.classList.remove(accordion__open);
      accContent?.classList.add(accordion__close);

      setTimeout(() => {
        if (accItem) {
          accItem.style.display = "none";
          siblingAccItem.style.display = siblingDisplay;
        }
      }, 100);
    } else {
      if (accItem) {
        accItem.style.display = "none";
        siblingAccItem.style.display = originalDisplay;
      }
      const siblingAccContent =
        siblingAccItem?.querySelector("[data-acc-content]");
      setTimeout(() => {
        siblingAccContent?.classList.remove(accordion__close);
        siblingAccContent?.classList.add(accordion__open);
      }, 1);
    }
  }, []);

  return (
    <div
      className={`rectangle-parent8 ${className}`}
      data-acc-item
      data-acc-header
      data-acc-original
      onClick={onAccordionHeaderClick}
    >
      <div className="frame-child16" />
      <img className="frame-child17" alt="" src="/group-34@2x.png" />
      <div className="click-the-download1" style={clickTheDownloadStyle}>
        {clickTheDownloadButton}
      </div>
    </div>
  );
};

GroupComponent1.propTypes = {
  className: PropTypes.string,
  clickTheDownloadButton: PropTypes.string,

  /** Style props */
  clickTheDownloadWidth: PropTypes.string,
};

export default GroupComponent1;
