import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from 'react-lottie';
import loadingAnimation from '../components/loading.json'; // Animation for fetch button
import loadingAnimation1 from '../components/loading1.json'; // Animation for download buttons
import "./CarouselContainer.css";

const CarouselContainer = ({ className = "" }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [videoData, setVideoData] = useState(null);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState("video");
  const [isVideoCardOpen, setIsVideoCardOpen] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false); // Loading state for fetch button
  const [loadingHD, setLoadingHD] = useState(false); // Loading state for HD download
  const [loadingSD, setLoadingSD] = useState(false); // Loading state for SD download
  const [loadingAudio, setLoadingAudio] = useState(false); // Loading state for Audio download


  
  const fetchVideoInfo = async () => {
    if (!videoUrl) {
      setError("Please enter a video URL.");
      setTimeout(() => setError(""), 3000);
      return;
    }

    setLoadingFetch(true); // Start loading for fetching video info
    try {
      const apiUrl = `https://fbapi.12dtechnology.com/facebook-api/app/info.php?url=${encodeURIComponent(videoUrl)}`;
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success && data.links && (data.links.hd_url || data.links.sd_url || data.links.audio_url) && data.thumbnailUrl) {
        setVideoData(data);
        setError("");
        setIsVideoCardOpen(true);
      } else {
        setError("No valid media found or video has no accessible link.");
        setVideoData(null);
        setIsVideoCardOpen(false);
        toast.error("Please provide a valid link. 😞", {
          icon: "❗",
          style: {
            backgroundColor: "#2b2b2b",
            color: "#fff",
            padding: "10px",
            borderRadius: "8px",
            border: "1px solid #e74c3c",
            fontSize: '14px',
            marginTop: "5rem",
            marginRight: '20px',
          },
          progressStyle: {
            background: "#e74c3c",
          },
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setTimeout(() => setError(""), 3000);
      }
    } catch (err) {
      setError(`An error occurred: ${err.message}`);
      setVideoData(null);
      setIsVideoCardOpen(false);
      setTimeout(() => setError(""), 3000);
    } finally {
      setLoadingFetch(false); // End loading for fetching video info
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleDownload = async (url, fileName, setLoading) => {
    setLoading(true); // Start loading for download
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);

      toast.success("Downloading successfully  👌", {
        icon: "✅",
        style: {
          backgroundColor: "#1E1E1E",
          color: "#fff",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
          fontSize: '14px',
          marginTop: "5rem",
          marginRight: '20px'
        },
        progressStyle: {
          backgroundColor: "#4CAF50",
        },
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
      });
    } catch (err) {
      console.error("Error during download:", err);
      setError("An error occurred while downloading the video.");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleCrossClick = () => {
    setVideoData(null);
    setVideoUrl("");
    setError("");
    setIsVideoCardOpen(false);
  };

  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setVideoUrl(text);
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err);
      setError("Failed to paste from clipboard.");
      setTimeout(() => setError(""), 3000);
    }
  };

  const renderCard = () => {
    if (!videoData) {
      return null;
    }

    const { links, thumbnailUrl, duration } = videoData;

    const hasHDVideo = !!links.hd_url;
    const hasSDVideo = !!links.sd_url;
    const hasAudio = !!links.audio_url;

    return (
      <div className="video-card">
        <div className="features-container">
          <div className="features-container-child" />
          <div className="features-container-item" />

          <div>
            <div className="download-row">
              <div className="download-icon" />
              <div className="download-btn-container">
                <div>
                  <img className="download-button-icon" src={thumbnailUrl} alt="Thumbnail" />
                </div>
                <div className="video-format-label-wrapper">
                  <div className="facebook-video1">Facebook Video</div>
                  <div className="video-format-placeholder">{duration}</div>
                </div>
              </div>
              <div className="cross-video-card">
                <button className="cross-btn" onClick={handleCrossClick}>
                  <img className="close-img" src="assets/close.png" alt="Close" />
                </button>
              </div>
            </div>
            <div className="ellipse-parent">
              <div className="quality-selector">
                <div className="quality-header">
                  <button className={`rectangle-parent ${activeTab === "video" ? "button-active" : ""}`} onClick={() => handleTabChange("video")}>
                    <div className="video">Video</div>
                  </button>
                  <button className={`rectangle-parent ${activeTab === "audio" ? "button-active" : ""}`} onClick={() => handleTabChange("audio")}>
                    <h3 className="audio">Audio</h3>
                  </button>
                </div>
              </div>
              <div className="render-options">
                <div className="download-btn-options">
                  <div className="download-header">
                    <div className="quality">Quality</div>
                    <div className="render">Render</div>
                    <div className="download">Download</div>
                  </div>
                </div>
                
      {activeTab === "video" ? (
  <>
    {!hasHDVideo && !hasSDVideo ? (
      <div className="no-audio-message">No video available at this time.</div>
    ) : (
      [
        hasHDVideo && { quality: "1080p (HD)", url: links.hd_url },
        hasSDVideo && { quality: "720p (SD)", url: links.sd_url },
      ].filter(Boolean).map((item) => (
        <div className="checkbox-values" key={item.quality}>
          <div className="resolution-values-parent">
            <div className="resolution-values">
              <div className="p-hd">{item.quality}</div>
            </div>
            <div className="render-checkboxes">
              <div className="p-hd">Yes</div>
            </div>
            <div className="loading-container">
              {item.quality === "1080p (HD)" && loadingHD ? (
               <Lottie
               options={{
                 loop: true,
                 autoplay: true,
                 animationData: loadingAnimation1,
                 rendererSettings: {
                   preserveAspectRatio: 'xMidYMid slice',
                 },
                 animationSpeed: 3.0 // Set this according to your needs
               }}
               height={40}
               width={100}
             />
              ) : item.quality === "720p (SD)" && loadingSD ? (
                <Lottie 
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingAnimation1,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    },
                    animationSpeed: 3.0 // Set this according to your needs
                  }}
                  height={40} 
                  width={100} 
                />
              ) : (
                <button className="rectangle-container" onClick={() => handleDownload(item.url, `${item.quality}.mp4`, item.quality === "1080p (HD)" ? setLoadingHD : setLoadingSD)}>
                  <div className="frame-child3" />
                  <div className="download1">Download</div>
                </button>
              )}
            </div>
          </div>
        </div>
      ))
    )}
  </>
) : (
  <>
    {!hasAudio ? (
      <div className="no-audio-message">No audio available at this time.</div>
    ) : (
      <div className="checkbox-values">
        <div className="resolution-values-parent">
          <div className="resolution-values">
            <div className="p-hd">Audio</div>
          </div>
          <div className="render-checkboxes">
            <div className="p-hd">Yes</div>
          </div>
          <div className="loading-container">
            {loadingAudio ? (
              <Lottie 
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loadingAnimation1,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  },
                  animationSpeed: 3.0 // Set this according to your needs
                }}
                height={40} 
                width={100} 
              />
            ) : (
              <button className="rectangle-container" onClick={() => handleDownload(links.audio_url, "audio.mp3", setLoadingAudio)}>
                <div className="frame-child3" />
                <div className="download1">Download</div>
              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="frame-item-5" />
        <div className="frame-item-6" />
      </div>
    );
  };

  return (
    <section className={`carousel-container ${className}`}>
      <div className="carousel-slides">
        <div className="carousel-content-container">
          <div className="carousel-content-container-child" />
          <div className="carousel-content-container-item" />
         
          <div className={`downloader-card-container ${isVideoCardOpen ? "open" : "closed"}`}>
            <div className="frame-child" />
            <div className="card-layout">
              <div className="card-layout-inner">
                <div className="downloader-title-wrapper-parent">
                  <div className="downloader-title-wrapper">
                    <h1 className="facebook-video-downloader-container">
                      <span className="facebook-video">{`Facebook Video  `}</span>
                      <span className="downloader">Downloader</span>
                    </h1>
                  </div>
                  <div className="input-container" />
                </div>
              </div>
              <div className="card-content-container">
                <div className="download-facebook-video-online-wrapper">
                  <h3 className="download-facebook-video">
                    Download Facebook Video Online for Free
                  </h3>
                </div>
                <div>
                  {!videoData ? (
                    <div className="Main">
                      <div className="input-container1">
                        <div>
                          <input
                            className="link-input"
                            placeholder="    Paste your link here..."
                            value={videoUrl}
                            onChange={(e) => setVideoUrl(e.target.value)}
                          />
                        </div>
                        
                        <div className="two-btn-searchbar">
                          <button className="paste-icon" onClick={handlePasteClick}>
                            <img className="paste-image" alt="" src="/assets/paste.png" />
                          </button>
                          <button className="fetch-button" onClick={fetchVideoInfo} disabled={loadingFetch}>
                            {loadingFetch ? (
                              <div className="lottie-container">
                                <Lottie 
                                  options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: loadingAnimation,
                                    rendererSettings: {
                                      preserveAspectRatio: 'xMidYMid slice'
                                    }
                                  }}
                                  height={30} 
                                  // width={72}
                                />
                              </div>
                            ) : 'Download'}
                          </button>
                        </div>
                      </div>
                      <div className="terms-container">
                        <h2 className="terms">
                          By using our service you accept our  <a href="https://privacy.zagtechnology.com" className="terms-link" target="_blank" rel="noopener noreferrer"> Terms of Service</a> and  <a href="https://privacy.zagtechnology.com/" className="terms-link" target="_blank" rel="noopener noreferrer"> Privacy Policy
                          </a>
                        </h2>
                      </div>
                      <div className="App-card-main">
                        <img className="App-card" src="/assets/card.png" alt="Card"/>
                        <div className="card-main-wrapper">
                          <h3 className="h3">
                            <span className="card-heading1">Install Our </span>
                            <span className="card-heading2">Facebook Video Downloader</span>
                            <span className="card-heading1"> Android App</span>
                          </h3>
                          <div className="para-wraper">
                            <span>
                              <p className="card-para">it’s fast and light</p>
                            </span>
                            <div>
                              <a href="https://play.google.com/store/apps/details?id=com.fbvideodownloader.downloadfbvideos" target="_blank" rel="noopener noreferrer">
                                <img className="app-icon" src="/assets/appicon.png" alt="App Icon" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-item-1" />
                      <div className="frame-item-2" />
                      <div className="frame-item-3" />
                      <div className="frame-item-4" />
                    </div>
                  ) : (
                    renderCard()
                  )}
                  {error && <p className="error-message">{error}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ToastContainer
            className="toast-container"
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </section>
  );
};

CarouselContainer.propTypes = {
  className: PropTypes.string,
};

export default CarouselContainer;