import Component1 from "./Component1";
import PropTypes from "prop-types";
import "./FeaturesDescription.css";

const HowToUse = ({ className = "" }) => {
  return (
    <section className={`features-description ${className}`}>
      <div className="features-header-container">
        <img
          className="features-header-container-child"
          loading="lazy"
          alt=""
          src="/group-31@2x.png"
        />


        {/* <img
          className="features-header-container-item"
          loading="lazy"
          alt=""
          src="/ellipse-21.svg"
        />
             <img
          className="features-header-container-item-2"
          loading="lazy"
          alt=""
          src="/rectangle-18.svg"
        />
              <img
          className="features-header-container-item-3"
          loading="lazy"
          alt=""
          src="/groupp-5.svg"
        /> */}
       
          <div className="mobile-download-container-wrapper">
            <div className="mobile-download-container">
              <div className="mobile-content-container">
                <div className="mobile-info">
                  <div className="mobile-title-container">
                    <h1 className="how-to-use1">How To Use?</h1>
                    <div className="mobile-paragraph-container">
                      <h3 className="follow-these-easy">
                        Follow These Easy Steps to Download and Keep Facebook
                        Videos
                      </h3>
                    </div>
                  </div>
                  <div className="steps-container">
                    <div className="frame-parent11">
                      <div className="frame-parent12">
                        <img
                          className="frame-child13"
                          loading="lazy"
                          alt=""
                          src="/group-19.svg"
                        />
                        <div className="steps-content">
                          <div className="paste-url-into-the-input-field-parent">
                            <h3 className="copy-the-url">
                              Copy the URL of the video
                            </h3>
                            <div className="copy-the-video">
                              Copy the video URL from the browser address bar to
                              the clipboard, or right-click the video and select
                              it from the context menu.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-parent13">
                        <img
                          className="frame-child14"
                          loading="lazy"
                          alt=""
                          src="/group-18.svg"
                        />
                        <div className="frame-wrapper3">
                          <div className="paste-url-into-the-input-field-parent">
                            <h3 className="copy-the-url">
                              Paste URL into the input field
                            </h3>
                            <div className="go-back-to">
                              Go back to the browser tab with the Facebook video
                              converter window open and paste the link into the
                              input field.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-parent13">
                        <img
                          className="frame-child14"
                          loading="lazy"
                          alt=""
                          src="/group-17.svg"
                        />
                        <div className="frame-wrapper4">
                          <div className="paste-url-into-the-input-field-parent">
                            <h3 className="click-the-download">
                              Click the "Download" button
                            </h3>
                            <div className="copy-the-video">
                              Just click the dropdown list before the "Download"
                              button in our online Facebook video converter and
                              select the preferred format option.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Component1 property1="Frame 43" />
              </div>
              <div className="mobile-circle-container">
                <div className="info-icon-circle" />
              </div>
              </div>
              </div>
              
              </div>
              <img
          className="features-right-childs"
          loading="lazy"
          alt=""
          src="/group-766.svg"
        />
              </section>
  );
};



export default HowToUse;