import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import UIDesign from "./pages/UIDesign";
import Header from "./components/Header";

function App() {
 
  return (
    <>
    {/* <Header/> */}
  <UIDesign/>
  </>
  );
}
export default App;
